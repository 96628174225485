// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatBot {
  height: 100%;
}

.input-message {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999;
}


@media (max-width: 1440px) {
  .grid-container {

    /* Set the height to 100vh on mobile */
  }

  .image-column {
    display: none;
  }
}


.card {
  padding: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/chatbot/chatbot.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,OAAO;EACP,WAAW;EACX,uBAAuB;EACvB,aAAa;AACf;;;AAGA;EACE;;IAEE,sCAAsC;EACxC;;EAEA;IACE,aAAa;EACf;AACF;;;AAGA;EACE,YAAY;AACd","sourcesContent":[".ChatBot {\n  height: 100%;\n}\n\n.input-message {\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background-color: white;\n  z-index: 9999;\n}\n\n\n@media (max-width: 1440px) {\n  .grid-container {\n\n    /* Set the height to 100vh on mobile */\n  }\n\n  .image-column {\n    display: none;\n  }\n}\n\n\n.card {\n  padding: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
