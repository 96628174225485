// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .toggleButton {
        display: block;
        /* Show toggle button on smaller screens */
        /* Add other styles as necessary */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/landing page/sidebar.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;QACd,0CAA0C;QAC1C,kCAAkC;IACtC;AACJ","sourcesContent":["@media (max-width: 768px) {\n    .toggleButton {\n        display: block;\n        /* Show toggle button on smaller screens */\n        /* Add other styles as necessary */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
